import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { ReactComponent as Info } from "../../assets/Icons/info.svg";
import { getMoreInfo } from "../../Redux/MoreInfo/moreInfoSlice";
import { useDispatch, useSelector } from "react-redux";
import { imageUrlCheck, translateText } from "../../utils/utils";
import Translate from "../MultiLanguage/Translate";

const PlayerShowCard = ({ data }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  const [scaleDown, setScaleDown] = useState(false);
  const [fontClass, setFontClass] = useState()
  const [initialClass, setInitialClass] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  let fontStylesClassName = ["gagalin", "gota"]

  useEffect(() => {
    setScaleDown(false)
    setTimeout(() => setInitialClass(true), 1000)
    setTimeout(() => setScaleDown(true), 3000);
  }, []);
  useEffect(() => {
    setFontClass(getRandomItem(fontStylesClassName))
  }, []);
  const moreInfoHandler = (id, showId) => {
    if (data?.show_id) {
      navigate(`/show-details/${data?.vanity_url ? data?.vanity_url : data?.show_id}`, { state: { showId: data?.show_id } })
      dispatch(
        getMoreInfo({
          moreInfo: true
        })
      )
    } else if (data?.event_id) {
      navigate(`/event/${data?.vanity_url ? data?.vanity_url : data?.event_id}`, { state: { eventId: data?.event_id } })
    }
  };
  function getRandomItem(arr) {
    // get random index value
    const randomIndex = Math.floor(Math.random() * arr.length);
    // get random item
    const item = arr[randomIndex];

    return item;
  }
  const replaceImage = (error) => {
    error.target.src = projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
  }
  return (
    <div className="playerShowCard">
      <div className="imageContainer">
        {
          imageUrlCheck(data?.banner || data?.logo_thumb) === true ? (
            <img src={data?.banner || data?.logo_thumb} onError={(e) => replaceImage(e)} alt="Banner" />


          ) : (
            <img src={`${process.env.REACT_APP_IMAGE_URL}${data?.banner || data?.logo_thumb}`} onError={(e) => replaceImage(e)} alt="Banner" />

          )
        }

        <div className={`metaData ${fontClass} ${initialClass ? " show" : ""}`}>
          {data?.image_title ?
            imageUrlCheck(data?.image_title) ? (
              <img src={data?.image_title} className={`${scaleDown ? "showTitleImage scaleDown showName" : "showName showTitleImage"}`} alt="Thumbnail" />
            ) : (
              <img src={`${process.env.REACT_APP_SHOW_IMAGE_URL}${data?.image_title}`} alt="Thumbnail" className={`${scaleDown ? "showTitleImage scaleDown showName" : "showName showTitleImage"}`} />
            ) : <h1 title={data?.show_name} className={`${scaleDown ? "scaleDown showName" : "showName"}`}>
              {data?.show_name}
            </h1>}
          {/* <span className="director">Shaheem</span> */}
          {/* <span className="country">India</span> */}
          {/* <p className="description">
              {data?.synopsis?.slice(0, 150)}
              {data?.synopsis?.length > 150 && "..."}
            </p> */}
          <div className="buttonContainer">
            <button className="play" onClick={() => moreInfoHandler(data?.vanity_url ? data?.vanity_url : data?.show_id, data?.show_id)}>
              <div className="icon">
                <PlayIcon />
              </div>
              <span><Translate textKey={'play'} /></span>

            </button>
            <button className="moreInfo" onClick={() => moreInfoHandler(data?.vanity_url ? data?.vanity_url : data?.show_id, data?.show_id)}>
              <div className="icon">
                <Info />
              </div>

              <span><Translate textKey={'more_info'} /></span>
            </button>
          </div>
        </div>
        <div className="bottomGradient"></div>
      </div>
    </div>
  );
};

export default PlayerShowCard;
