import { fetchApiData } from "../../utils/utils";

export const fetchLiveChannels = async (appInfo) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const api = "schedule/fastchannels";
    const params = {
        timezone:timeZone
    }
    return await fetchApiData(appInfo,api,params)
}
export const fetchFastChannels = async (appInfo,channelId) => {
    const api = `fastchannel/${channelId}`;  
    return await fetchApiData(appInfo, api);
  };
