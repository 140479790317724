import { fetchApiData, fetchApiDataV2 } from "../../utils/utils"

export const getShowDetails=async (appInfo,showId)=>{
    
    const api=`show/${showId}`
        return await fetchApiDataV2(appInfo,api)   
}
export const getSimilarVideos=async (appInfo,showId)=>{
    const api=`show/similar/${showId}`
        return await fetchApiDataV2(appInfo,api)  
}
