import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ideabizSubscribePhone } from "../../Screens/Subscription/service";

const IdeabizSubscribeModal = ({ appInfo, ideabizToken, ideabizPlan, setSubscribeModal, setPinVerifyModal, setServerRef, setSubscriberPhone }) => {

  const [input, setInput] = useState("");
  const [errors, setErrors] = useState({});
  const [submitButtonActive, setSubmitButtonActive] = useState(true);

  const validation = async () => {
    let errors = {};
    let formIsValid = true;
    if (!input.trim()) {
      formIsValid = false;
      errors.phone = "Phone number is required.";
    }
    if (input.length < 9 || input.length > 10) {
      formIsValid = false;
      errors.phone = "Invalid phone number.";
    }
    setErrors(errors);
    return formIsValid;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const isValid = await validation()
      if (isValid) {
        const phoneWithCode = "94" + input
        setSubscriberPhone(phoneWithCode)
        localStorage.setItem("selectedIdeabizNumber", input);
        setSubmitButtonActive(false);
        const inputData = {
          token: ideabizToken,
          phone: phoneWithCode,
          serviceId: ideabizPlan?.ideabiz_keyword
        }
        const response = await ideabizSubscribePhone(appInfo, inputData);
        if (response?.status === 200 && response?.data.success == true) {
          setServerRef(response?.data?.serverRef)
          toast.success(response?.data?.message, {
            position: "top-center",
          });
          setTimeout(() => {
            setSubscribeModal(false)
            setPinVerifyModal(true)
          }, 3000);
        } else {
          let errors = {};
          errors.phone = response?.data?.message;
          setErrors(errors);
          setSubmitButtonActive(true)
          // setTimeout(() => {
          //   setSubscribeModal(false)
          //   setPinVerifyModal(true)
          // }, 3000);
        }
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
      });
      setSubmitButtonActive(true)
    }
  };

  return (
    <div className="otpModal">
      <ToastContainer />
      <div className="overlayOtp"></div>
      <div className="otpContainer">
        <h4>Welcome to CeyFliX</h4>
        <h6>Your {ideabizPlan?.subscription_type_name} Subscription is Rs {ideabizPlan?.ideabiz_price}+ Tax.</h6>
        <label htmlFor="otp">Enter (Dialog) Mobile number to subscribe.</label>
        <input
          type="number"
          id="phone"
          value={input || ""}
          onChange={(e) => {
            setInput(e.target.value);
          }}
        />
        {errors?.phone && <span className="error">{errors.phone}</span>}
        <div className="buttonContainer" onClick={(e) => submitButtonActive && submitHandler(e)}>
          <div className="background"></div>
          <button disabled={!submitButtonActive}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default IdeabizSubscribeModal;
