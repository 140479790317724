import React, { useMemo } from "react";
import { useState, Fragment } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { countries } from "country-data-list";
import * as service from "./service";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import CurrentPasswordModal from "../../../Components/Modals/CurrentPasswordModal";
import CancelSubscriptionModal from "../../../Components/Modals/CancelSubscriptionModal";
import LogoutAllModal from "../../../Components/Modals/LogoutAllModal";
import { getUserDetails } from "../../../Redux/UserDetailsSlice/UserDetailsSlice";
import Loading from "../../../Components/Loading/Loading";
import Accounts from "./Accounts";
import PlanDetails from "./PlanDetails";
import StreamingActivity from "./StreamingActivity";
import Translate from "../../../Components/MultiLanguage/Translate";

const AccountSettings = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const [accountDetails, setAccountDetails] = useState({});
  const [values, setValues] = useState({});
  const [PasswordModal, setPasswordModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [cancelSubModal, setCancelSubModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [profileWill, setProfileWill] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("account");
  const [activeTabName, setActiveTabName] = useState( <Translate textKey={'account_caps'} />);

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    window.scroll(0, 0);
    fetchAccountDetails();
    // setCountryListOption(
    //   countries?.all?.map((item, index) => {
    //     const { alpha2, name } = item;
    //     return (
    //       <option key={index} value={alpha2}>
    //         {name}
    //       </option>
    //     );
    //   })
    // );
  }, []);

  const fetchAccountDetails = async (isUpdate) => {
    setLoading(true);
    try {
      const accountDetailsResponse = await service.getAccountDetails(appInfo);
      if (accountDetailsResponse?.status === 200) {
        setValues({
          ...values,
          email: accountDetailsResponse?.data?.data?.user_email,
          firstName: accountDetailsResponse?.data?.data?.first_name,
          lastName: accountDetailsResponse?.data?.data?.last_name,
          phone: accountDetailsResponse?.data?.data?.phone,
          country: accountDetailsResponse?.data?.data?.country_code,
        });
        setAccountDetails(accountDetailsResponse?.data?.data);
        if (isUpdate) {
          dispatch(
            getUserDetails({
              userDetails: accountDetailsResponse?.data?.data?.first_name,
            })
          );
        }
        setLoading(false);
      }
    } catch (err) {}
  };
  const handleUpdate = (item) => {
    const { name, value } = item?.target;
    handleChange(name, value);
  };

  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };


  const validation = () => {
    let validationStatus = true;
    let errors = {};
    let nameRegex = /^[a-zA-Z ]{3,30}$/;
    const { firstName, lastName, country } = values;

    if (!firstName) {
      errors.firstName = <Translate textKey={'first_name_required'}/>
      validationStatus = false;
    } else if (!firstName?.match(nameRegex)) {
      errors.firstName =
        <Translate textKey={'name_validation_message'}/>;
      validationStatus = false;
    }

    if (!lastName) {
      errors.lastName = <Translate  textKey={'last_name_required'}/>;
      validationStatus = false;
    } else if (!lastName?.match(nameRegex)) {
      errors.lastName =
        <Translate  textKey={'name_validation_message'}/>;
      validationStatus = false;
    }

    if (!country) {
      errors.country = <Translate  textKey={'country_required'}/>;
      validationStatus = false;
    } else if (country === <Translate textKey={'select_country'}/>) {
      errors.country = <Translate textKey={'country_required'}/>;
      validationStatus = false;
    }
    setErrors(errors);
    return validationStatus;
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (validation() === true) {
      setPasswordModal(true);
      
    } else {
      toast.error(<Translate textKey={'check_the_fields'}/>, {
        position: "bottom-center",
      });
    }
  };
  const updateAccountDetails = async () => {
    try {
      const response = await service.updateAccountdetails(appInfo, values);
      if (response?.data?.success === true) {
        toast.success(<Translate textKey={'account_updated_message'}/>, {
          position: "bottom-center",
        });
        setPasswordModal(false);
        fetchAccountDetails(true);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }
  };

  const handleFileChange = (e) => {
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (!allowedExtensions.exec(e?.target?.files[0]?.name)) {
      alert(<Translate textKey={'invalid_extension_message'}/>);
    } else {
      const name = e?.target?.files[0]?.name;
      const files = e?.target?.files;
      setValues({
        ...values,
        file: files[0],
      });
      setProfileWill(URL.createObjectURL(files[0]));
    }
  };

  const tabChange = (tabName) => {
    setActiveTab(tabName);
    switch (tabName) {
      case "account":
        setActiveTabName(<Translate textKey={'account_caps'}/>);
        break;
      case "plan":
        setActiveTabName(<Translate textKey={'plan_details_caps'}/>);
        break;
      case "recentDevice":
        setActiveTabName(<Translate textKey={'recent_device_streaming_activity_caps'}/>);
        break;
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="accountSettings">
      {PasswordModal && (
        <CurrentPasswordModal
          fetchAccountDetails={fetchAccountDetails}
          updateAccountDetails={updateAccountDetails}
          values={values}
          handleUpdate={handleUpdate}
          handleChange={handleChange}
          setValues={setValues}
          setErrors={setErrors}
          errors={errors}
          setPasswordModal={setPasswordModal}
        />
      )}
      {cancelSubModal && (
        <CancelSubscriptionModal
          fetchAccountDetails={fetchAccountDetails}
          setCancelSubModal={setCancelSubModal}
          subId={accountDetails?.sub_id}
        />
      )}
      {logoutModal && (
        <LogoutAllModal
          setLogoutModal={setLogoutModal}
          modalType={{
            type: "logoutAll",
            heading: <Translate textKey={'sign_out_from_all_devices'}/>,
          }}
          appInfo={appInfo}
        />
      )}

      <div className="leftContainer">
        <ul className="tabs">
          <li
            className={activeTab === "account" ? "active" : "tab"}
            onClick={() => tabChange("account")}
          >
            <Translate textKey={'account_caps'}/>
          </li>
          {
            accountDetails?.sub_id &&
          <li
            className={activeTab === "plan" ? "active" : "tab"}
            onClick={() => tabChange("plan")}
          >
          <Translate textKey={'plan_details_caps'}/>
          </li>
          }
          <li
            className={activeTab === "recentDevice" ? "active" : "tab"}
            onClick={() => tabChange("recentDevice")}
          >
            <Translate textKey={'recent_device_streaming_activity_caps'}/>
          </li>
          <li onClick={()=>setLogoutModal(true)}><Translate textKey={'signout_all_device_caps'} /></li>
        </ul>
      </div>

      <div className="rightContainer">
        <div className="top">
          <h1 className="heading">{activeTabName}</h1>
        </div>
          {
            activeTab=="account" &&
        <Accounts
          profileWill={profileWill}
          accountDetails={accountDetails}
          handleFileChange={handleFileChange}
          errors={errors}
          handleUpdate={handleUpdate}
          values={values}
          setErrors={setErrors}
          onSubmitHandler={onSubmitHandler}
        />
          }
        {
          activeTab ==='plan'&&
        <PlanDetails projectInfo={projectInfo} accountDetails={accountDetails} setCancelSubModal={setCancelSubModal} />
        }
        {
          activeTab === 'recentDevice' &&
          <StreamingActivity/>
        }
      </div>

      
    </div>
  );
};

export default AccountSettings;
