import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { imageUrlCheck } from "../../utils/utils";
import parse from "html-react-parser";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { useWindowWidth } from "@react-hook/window-size";
import Translate from "../MultiLanguage/Translate"

const EpisodeCard = ({ data, count,watchVideoHandler }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [descriptionCount, setDescriptionCount] = useState(110);
  const [isSeeLess, setIsSeeLess] = useState(false);
  const windowSize = useWindowWidth()

  useEffect(()=>{
    if(windowSize<=480){
      setDescriptionCount(50)
    }
  },[windowSize])

  useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  const descriptionHandler = (e,flag) => {
    e.stopPropagation();

    if (flag === "more") {
      setDescriptionCount(data?.video_description?.length);
      setIsSeeLess(true);
    } else if (flag === "less") {
      setIsSeeLess(false);
      setDescriptionCount(110);
    }
  };
  return (
    <div className={thumbnailOrientation==="PORTRAIT"?"episodeCard episodePortrait":"episodeCard episodeLandscape"} onClick={()=>watchVideoHandler(data)}>
        { data?.is_free_video === true || data?.free_video === true ? (
      <div className="premiumStatus">
          <div className="free">
            <img
              src={require("../../assets/Images/free_icon.png")}
              alt="FreeIcon"
            />
          </div>
      </div>
        ) : null}
      <div className="leftContainer">
        <span className="sNo">{count}</span>
        <div className="imageContainer">
          {imageUrlCheck(
            thumbnailOrientation === "PORTRAIT"
              ? data?.thumbnail
              : data?.thumbnail_350_200
          ) ? (
            <img
              src={
                thumbnailOrientation === "PORTRAIT"
                  ? data?.thumbnail
                  : data?.thumbnail_350_200
              }
              alt="Thumbnail"
            />
          ) : (
            <img
              src={
                thumbnailOrientation === "PORTRAIT"
                  ? process.env?.REACT_APP_IMAGE_URL + data?.thumbnail
                  : process.env?.REACT_APP_IMAGE_URL + data?.thumbnail_350_200
              }
              alt="Thumbnail"
            />
          )}
          <div className="playIconContainer">
            <PlayIcon />
          </div>
          {data?.watched_duration > 0&&
            <div className="continueWatchingBar">
            <div
              className="line"
              style={{
                width:
                  data?.watched_percentage <= 1
                    ? 1 + "%"
                    : data?.watched_percentage + "%",
              }}
            ></div>
          </div>
          }
          
        </div>
      </div>
      <div className="rightContainer">
        <div className="top">
          <div className="left">
            <h4>{data?.video_title}</h4>
          </div>
          <div className="right">
            <span>{data?.duration_text}</span>
          </div>
        </div>
        <div className="bottom">
          {data?.video_description && (
            <p className="description">
              {parse(
                data?.video_description
                  ?.slice(0, descriptionCount)
                  .split("\n")
                  ?.join("<br>")
              )}
              {data?.video_description?.length > descriptionCount && (
                <>
                  ...
                  <span
                    className="moreLess"
                    onClick={(e) => descriptionHandler(e,"more")}
                  >
                    {" "}
                    <Translate textKey={'more'} />
                  </span>
                </>
              )}
              {isSeeLess && (
                <span
                  className="moreLess"
                  onClick={(e) => descriptionHandler(e,"less")}
                >
                  {" "}
                  <Translate textKey={'less'} />
                </span>
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EpisodeCard;
