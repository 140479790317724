import React, { useState } from 'react'
import ShowCard from '../../../Components/ShowCard/ShowCard';
import MagazineCard from '../../../Components/MagazineCard/MagazineCard';
const SwiperContainer = ({data,type,index,showCount,categoryData}) => {
 
  return (
    
    <div className={showCount>17?"show visibleShowAllButton":"show"}>
     
     {data?.type!=="MAGAZINE" ?
      (
          <ShowCard
          data={data}
          season={false}
          metaData={true}
          type={type}
          index={index}
          showCount={showCount}
          categoryData={categoryData}
        />
      ):
      (
        <MagazineCard
        data={data}
        url={data.url}
        metaData={true}
        cardHover={true}
        />
      )
     
     
      
    }
  </div>
  )
}

export default SwiperContainer