import React from "react";

const SmallLoading = () => {
  return (
    <div className="smallLoading">
      <div className="loading-container">
          <div class="loading-children-container">
            <div class="nf-loading-spinner"></div>
          </div>
        </div>
    </div>
  );
};

export default SmallLoading;
