import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoutes = () => {
  const user = useSelector((state) => state?.user?.value);
  const location = useLocation()
    if(user){
        return(
            <Outlet/>
        )
    }else{
        return <Navigate to="/login" state={location?.pathname==="/tv"?{tv:true,location:location}:{}} />
    }
 
}

export default ProtectedRoutes