import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageUrlCheck } from "../../utils/utils";
import { ReactComponent as AddListIcon } from "../../assets/Icons/addToWatchlist.svg";
import { ReactComponent as RemoveFromWatchListIcon } from "../../assets/Icons/removeFromWatchlist.svg";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { updateWatchlistData } from "../../network/service";
import { useNavigate } from "react-router-dom";
import { getMoreInfoOther } from "../../Redux/MoreInfo/moreInfoOtherSlice";
import Translate from "../MultiLanguage/Translate";


const SimiliarShowsCard = ({ data,handleReset }) => {
  const user = useSelector((state) => state?.user?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const isMoreInfoOther = useSelector((state) => state?.moreInfoOther?.value);
  const projectInfo = useSelector((state) => state?.projectInfo?.value);

  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
 const [watchlistStatus,setWatchlistStatus] = useState()
 const navigate = useNavigate() 
 const dispatch = useDispatch()
 const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };

  useEffect(()=>{
    if(data?.watchlist_flag===1){
        setWatchlistStatus("added")
    }else{
        setWatchlistStatus("removed")
    }
  },[data])
 useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  const showImage = () => {
    if (
      imageUrlCheck(
        thumbnailOrientation === "PORTRAIT" ? data?.logo : data?.logo_thumb
      )
    ) {
      return (
        <img
          src={
            thumbnailOrientation === "PORTRAIT" ? data?.logo : data?.logo_thumb
          }
          alt="Thumbnail"
        />
      );
    } else {
      return (
        <img
          src={
            thumbnailOrientation === "PORTRAIT"
              ? process.env?.REACT_APP_IMAGE_URL + data?.logo
              : process.env?.REACT_APP_IMAGE_URL + data?.logo_thumb
          }
          alt="Thumbnail"
        />
      );
    }
  };

  const updateWatchlist = async (showId, flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showId, flag);

      if (response?.status === 200) {
        if (flag === 1) {
          setWatchlistStatus("added");
        } else {
          setWatchlistStatus("removed");
        }
      }
    } catch (err) {
    }
  };

  const addToWatchlist = (e) => {
    e.stopPropagation();

    if (user) {
      updateWatchlist(data?.show_id, 1);
    } else {
      navigate("/login");
    }
  };
  const removeFromWatchlist = (e) => {
    e.stopPropagation();
    updateWatchlist(data?.show_id, 0);
  };

  const showHandleClick=()=>{
    handleReset()
    if(isMoreInfoOther?.isModal){
      dispatch(
        getMoreInfoOther({
          moreInfoOther:{
            isModal:true,
            showId:data?.vanity_url?data?.vanity_url:data?.show_id
          }
        })
      )
    }else{

      navigate(`/show-details/${data?.vanity_url}`)
    }
  }
  return (
    <div className="similiarShowsCard" onClick={()=>showHandleClick()}>
         { data?.is_free_video === true || data?.free_video === true ? (
      <div className="premiumStatus">
          <div className="free">
            <img
              src={require("../../assets/Images/free_icon.png")}
              alt="FreeIcon"
            />
          </div>
      </div>
        ) : null}
      <div className="imageContainer">
        {showImage()}
      <span className="duration">{data?.duration_text}</span>
        <div className="playIconContainer">
           <PlayIcon/>
        </div>
      </div>
      <div className="metaInformation">
        <h1 className="showName" title={data?.show_name}>{data?.show_name}</h1>
        <div className="extraInfo">
          <div className="left">
            <span className="rating">{data?.rating}</span>
            <span className="year">{data?.year}</span>
          </div>
          <div className="right">
            {
              watchlistStatus === "added" ? (
                <button className="addToList" onClick={(e) => removeFromWatchlist(e)}>
                  <span className="tooltip remove"><Translate textKey={'remove_from_mylist'} /></span>
                  <RemoveFromWatchListIcon />
                </button>
              ) : (
                <button className="addToList" onClick={(e) => addToWatchlist(e)}>
                  <span className="tooltip"><Translate textKey={'add_to_mylist'} /></span>
                  <AddListIcon />
                </button>

                    )
                }
            </div>
        </div>
        {
            data?.synopsis&&
        <p className="description">
            {
                data?.synopsis?.slice(0,150)
            }
            {
                data?.synopsis?.length>150&&"..."
            }
        </p>
        }
      </div>
    </div>
  );
};

export default SimiliarShowsCard;
