import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-ads';
import 'videojs-ima';
export const VideoJS = (props) => {

  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  // const pauseRef = React.useRef(null);
  const { options, onReady, pauseVideo } = props;
  const [isfullScreen,setIsFullScreen]= useState(false);
  const location = useLocation()
  let uid = localStorage.getItem("gid");
  let user_id = localStorage.getItem("userId");
  if (user_id) {
    uid = user_id
  }
  videojs.Vhs.xhr.beforeRequest = function (options) {
    if (options?.uri.includes(".m3u8?id=")) {
      options.headers = {
        pubid: projectInfo?.projectConfig?.pubid,
        channelid: projectInfo?.projectConfig?.channelid,
        uid: uid,
        "access-token": accessToken
      };
    } else {


    }
    return options;
  };

  React.useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      });

      player.on('fullscreenchange', () => {
        if (player.isFullscreen()) {
          setIsFullScreen(true);
        } else {
          setIsFullScreen(false);
        }
      });


      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      //   const player = playerRef.current;

      //   player.autoplay(options.autoplay);
      //   player.src(options.sources);
    }
  }, [options, videoRef]);


  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    if (props?.adUrl) {
      var imaOptions = {
        adTagUrl: props?.adUrl
      };

      player.ima(imaOptions)

    }



    // if(pauseVideo){
    //     let video = document.querySelector('video');
    //     let isPause = false ;
    //       let observer = new IntersectionObserver((entries, observer) => { 
    //       entries.forEach(entry => {
    //         console.log(entry.intersectionRatio,"qqqqq");
    //         if(entry.intersectionRatio >= 0.25){
    //           videoRef.current.play();


    //         }
    //         else if(entry.intersectionRatio >= 0.14)  {

    //           videoRef.current.pause()

    //         } 
    //         else if(entry.intersectionRatio >= 0.40){
    //           videoRef.current.play(); 

    //         }
    //         else if(entry.intersectionRatio >= 0.09){
    //           videoRef.current.pause()

    //         }


    //     })
    //     }, {threshold: 0.25});
    //     observer.observe(video)
    // }






    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;

      }
    };
    // playerRef.current.on("dispose",()=>{
    //   playerRef.current.pause()
    // })



  }, [videoRef]);
  useEffect(() => {
    const videoPlayerElements = document.querySelectorAll('.playerContainer, .videoContainer , .videoPlayer');
    
    if (videoRef.current && videoPlayerElements.length > 0) {
      if (isfullScreen) {
        videoRef.current.classList.add('video-rotate');
        videoPlayerElements.forEach((element) => element.classList.add('rotate-fullscreen'));
      } else {
        videoRef.current.classList.remove('video-rotate');
        videoPlayerElements.forEach((element) => element.classList.remove('rotate-fullscreen'));
      }
    }
  }, [isfullScreen]);  

  return (
    <div data-vjs-player>
      <video ref={videoRef} className='video-js vjs-big-play-centered'>

        {
          props?.closedCaptions?.map((item, index) => (
            <track key={index} kind='captions' src={item?.closed_caption_url} srclang={item?.short_code} label={item?.language_name} default />
          ))
        }

        {
          props?.subtitles?.map((item, index) => (
            <track key={index} kind='captions' src={item?.subtitle_url} srclang={item?.short_code} label={item?.language_name} default />
          ))
        }

      </video>
    </div>
  );
}

export default VideoJS;