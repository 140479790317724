import { ChannelBox, ChannelLogo } from "planby";
import { useDispatch } from "react-redux";
import { getSelectedChannel } from "../../../Redux/SelectedChannel/selectedChannelSlice";
import { getSelectedSchedule } from "../../../Redux/SelectedScheduleChannel/selectedScheduleChannelSlice";

export const ChannelItem = ({ channel }) => {
  const { position, logo,uuid } = channel;
  const dispatch = useDispatch()

  const selectedChannelHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(
      getSelectedChannel({
        selectedChannel:{channel_id:uuid}
      })
    )
    dispatch(
      getSelectedSchedule({
        selectedSchedule:{channel_id:uuid}
      })
    )
  }
  return (
    <ChannelBox {...position} >
      {/* Overwrite styles by add eg. style={{ maxHeight: 52, maxWidth: 52,... }} */}
      {/* Or stay with default styles */}
      <div onClick={()=>selectedChannelHandler()}>
      <ChannelLogo
        src={logo}
        alt="Logo"
        style={{ maxHeight: 52, maxWidth: 52 }}
      />
      </div>
    </ChannelBox>
  );
};
