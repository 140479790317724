import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as Arrow } from "../../../assets/Icons/filledArrow.svg";
import EpisodeCard from '../../../Components/EpisodeCard/EpisodeCard';
import Translate from '../../../Components/MultiLanguage/Translate';

const EpisodeContainer = ({showDetails,watchVideoHandler}) => {
    const [isSeasonDropDown,setIsSeasonDropDown] = useState(false)
    const [selectedSeason,setSelectedSeason] = useState("Season 1")
    const [episodes,setEpisodes] = useState([])
    const seasonRef= useRef()
    const seasonListRef= useRef()
    useEffect(() => {
        let handleOutClick = (e) => {
          if (
            !seasonRef?.current?.contains(e.target)
          ) {
            setIsSeasonDropDown(false); //outside click
          }
        };
    
        window.addEventListener("click", handleOutClick);
        return () => {
          window.addEventListener("click", handleOutClick);
        };
      }, [seasonListRef,seasonRef]);

    useEffect(()=>{
        if(showDetails?.single_video===0&&
            showDetails?.videos?.length>0&&
            showDetails?.videos[0]?.videos
        )
        {
            setEpisodes(showDetails?.videos[0]?.videos)
        }else{
            setEpisodes(showDetails?.videos)
        }
    },[showDetails])
    const seasonHandler = (item) =>{
        setSelectedSeason(item?.season)
        setEpisodes(item?.videos)
    }
  return (
    <div className='episodeContainer'>
        <div className="head">
            <div className="left">
                <h1 className="heading"><Translate textKey={'episodes'}/></h1>
            </div>
            {
                showDetails?.single_video===0&&
                showDetails?.videos?.length > 1&&
                showDetails?.videos[0]?.videos &&
            <div className="right">
                <div ref={seasonRef} className="seasonContainer" onClick={()=>setIsSeasonDropDown(!isSeasonDropDown)()}>
                    <span className="selected">{selectedSeason}</span>
                    <Arrow/>
                    <ul ref={seasonListRef} className={isSeasonDropDown?"listContainer active":"listContainer noActive"}>
                        {
                            showDetails?.videos?.map((item,index)=>(
                                <li className="list" onClick={()=>seasonHandler(item)}>{item?.season}<span className="episodes">({item?.videos?.length} <Translate textKey={'episodes'}/>)</span></li>
                            ))
                        }
                        
                    </ul>
                </div>
            </div>
            }
        </div>
        <div className="body">
            {
                episodes?.map((item,index)=>(

                    <EpisodeCard data={item} watchVideoHandler={watchVideoHandler} count={index+1} />
                ))
            }
        </div>
    </div>
  )
}

export default EpisodeContainer
