import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { applyCouponCode, stripeSession, cinetPayApiSession } from "./service";
import { ToastContainer, toast } from "react-toastify";
import {
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import PaypalButtonWrapper from "./PaypalButtonWrapper";
import { useNavigate } from "react-router-dom";
import Translate from "../../Components/MultiLanguage/Translate";
const Payment = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const androidData = useSelector((state) => state?.deviceSubscription?.value)
  const user = useSelector((state) => state?.user?.value);
  const [paymentGateways, setPaymentGateways] = useState({
    cinetPay: false,
    stripe: false,
    paypal: false,
    ideabiz: false,
  });
  const [selectedSubscriptionData, setSelectedSubscriptionData] = useState({});
  const [couponCode, setCouponCode] = useState();
  const [error, setError] = useState();
  const [CouponSuccess, setCouponSuccess] = useState(false);
  const [paypalLink, setPaypalLink] = useState("")
  const [paypalSub, setPaypalSub] = useState(false)
  const location = useLocation();
  const navigate = useNavigate();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    if (selectedSubscriptionData) {
      localStorage.setItem("selectedSubId", selectedSubscriptionData?.subscription_id);
      localStorage.setItem("deviceType", androidData?.isAndroid ? "android-web" : "web");

      const user_data = {
        transaction_type: 1,
        subscription_id: selectedSubscriptionData?.subscription_id,
        uid: user,
        amount: selectedSubscriptionData?.price,
        pubid: projectInfo?.projectConfig?.pubid,
        country_code: projectInfo?.countryCode,
        device_type: androidData?.isAndroid ? "android-web" : "web",
      };
      const user_details = encodeURIComponent(JSON.stringify(user_data));
      const link = `https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=TL7WSPLLZZ4WS&custom=${user_details}&discount=10&currency_code=USD&upload=1`
      setPaypalLink(link)
    }

  }, [selectedSubscriptionData])
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.subscription) {
      setSelectedSubscriptionData(location?.state?.subscription);
    }
  }, [location?.state]);
  
  useEffect(() => {
    const gateways = projectInfo?.projectConfig?.config?.WEBSITE_PAYMENT_GATEWAYS;

    if (gateways) {
      setPaymentGateways({
        cinetPay: gateways.includes('CINETPAY'),
        stripe: gateways.includes('STRIPE'),
        paypal: gateways.includes('PAYPAL'),
        ideabiz: gateways.includes('IDEABIZ'),
      });
    } else {
      console.log('WEBSITE_PAYMENT_GATEWAYS not found');
    }
  }, [])

  const couponCodeApi = async () => {
    try {
      const couponCodeResponse = await applyCouponCode(
        appInfo,
        couponCode?.couponCode,
        selectedSubscriptionData?.subscription_id
      );
     
      if (couponCodeResponse?.status === 200) {
        setError(null)
        setCouponSuccess(true);
        localStorage.setItem("couponId", couponCodeResponse?.data?.data?.coupon_id);

        setCouponCode({
          ...couponCode,
          couponId: couponCodeResponse?.data?.data?.coupon_id,
        });
      } else {
        setError(couponCodeResponse?.data?.message);
      }
    } catch (err) { }
  };


  const stripeApi = async () => {
    if (CouponSuccess) {
      const paymentInfo = {
        subId: selectedSubscriptionData?.subscription_id,
        couponId: couponCode?.couponId,
      };
      try {
        const stripeResponse = await stripeSession(appInfo, paymentInfo);
        if (stripeResponse?.status === 200) {
          window.open(stripeResponse?.data?.url, "_self")

        }
      } catch (err) {
        toast.error(err?.response?.data?.message, {
          position: "bottom-center",
        });
      }
    } else {
      const paymentInfo = {
        subId: selectedSubscriptionData?.subscription_id,
      };
      try {
        const stripeResponse = await stripeSession(appInfo, paymentInfo);
       
        if (stripeResponse?.status === 200) {
          window.open(stripeResponse?.data?.url, "_self")
        }
      } catch (err) {
        toast.error(err?.response?.data?.message, {
          position: "bottom-center",
        });
      }
    }
  };

  const cinetPayApi = async () => {

    const paymentInfo = {
      subId: selectedSubscriptionData?.subscription_id,
    };
    try {
      const cinetPayApiResponse = await cinetPayApiSession(appInfo, paymentInfo);
      if (cinetPayApiResponse?.status === 200) {
        window.open(cinetPayApiResponse?.data?.data, "_self")
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "bottom-center",
      });
    }

  };

  const applyCouponCodeHandler = () => {
    if (!couponCode?.couponCode) {
      setError("Enter a coupon code!");
    } else {
      couponCodeApi();
    }
  };

  const stripePaymentHandler = () => {
    localStorage.setItem("selectedAmount", selectedSubscriptionData?.price);

    stripeApi();
  };

  const ideabizHandler = () => {
    localStorage.setItem("selectedAmount", selectedSubscriptionData?.price);
    localStorage.setItem("selectedIdeabizAmount", selectedSubscriptionData?.ideabiz_price);
    navigate(`/ideabiz`, {
      state: { subscription: selectedSubscriptionData, appInfo },
    })
  }


  return (
    <div className="paymentScreen">
      <div className="wrapper">

        <h1 className="heading"><Translate textKey={'payment_gateway'} /></h1>
        <div className={(paymentGateways.stripe & paymentGateways.cinetPay & paymentGateways.paypal) ? "mainContainer  modifyDiv" : (paymentGateways.stripe & !paymentGateways.cinetPay & paymentGateways.paypal) || (!paymentGateways.stripe & paymentGateways.cinetPay & paymentGateways.paypal) || (paymentGateways.stripe & paymentGateways.cinetPay & !paymentGateways.paypal) ? "mainContainer centerDiv" : "mainContainer"}>
          {
            paymentGateways.stripe ? (
              <div className={(paymentGateways.stripe & !paymentGateways.cinetPay & paymentGateways.paypal) && (paymentGateways.stripe & paymentGateways.cinetPay & !paymentGateways.paypal) ? "left" : (paymentGateways.stripe & !paymentGateways.cinetPay & !paymentGateways.paypal) ? "left no-border" : "left small"}>
                {
                  selectedSubscriptionData?.is_coupon === false &&
                  (<div className="couponCodeContainer">
                    <label htmlFor="couponCode"><Translate textKey={'coupon_code'} /></label>
                    <div className={(paymentGateways.stripe & !paymentGateways.cinetPay & paymentGateways.paypal) && (paymentGateways.stripe & paymentGateways.cinetPay & !paymentGateways.paypal) ? "inputContainer" : "inputContainer small"}>
                      <input
                        name="couponCode"
                        type="text"
                        value={couponCode?.couponCode || ""}
                        onChange={(e) =>
                          setCouponCode({ couponCode: e.target.value })
                        }
                      />
                      <div
                        className="buttonContainer"
                        onClick={() => applyCouponCodeHandler()}
                      >
                        <div className="background" />
                        <button><Translate textKey={'apply'} /></button>
                      </div>
                    </div>
                    {error && <span className="error">{error}</span>}
                    {CouponSuccess && !error && <span className="success"><Translate textKey={'coupon_applied'} /></span>}
                  </div>)
                }
                <div className="paymentGatewayContainer">
                  {
                    paymentGateways.stripe &&
                    <>
                      <h2 className="heading"><Translate textKey={'select_payment_gateway'} /></h2>
                      <ul className="gateways">
                        {projectInfo?.projectConfig?.config?.WEBSITE_STRIPE_PAYMENT_METHODS.map((item) => {
                          return <li onClick={stripePaymentHandler}>
                            <div className="imageContainer">
                              <img
                                src={item?.image}
                                alt={item?.type}
                              />
                            </div>
                          </li>
                        })}
                        {/* <li onClick={stripePaymentHandler}>
                          <div className="imageContainer">
                            <img
                              src={require("../../assets/Images/mastercard.png")}
                              alt="MasterCard"
                            />
                          </div>
                        </li>
                        <li onClick={stripePaymentHandler}>
                          <div className="imageContainer">
                            <img
                              src={require("../../assets/Images/visa.png")}
                              alt="Visa"
                            />
                          </div>
                        </li>
                        <li onClick={stripePaymentHandler}>
                          <div className="imageContainer">
                            <img
                              src={require("../../assets/Images/americanExpress.png")}
                              alt="AmericanExpress"
                            />
                          </div>
                        </li> */}
                        {paymentGateways.ideabiz && selectedSubscriptionData?.ideabiz_keyword && <li onClick={ideabizHandler}>
                          <div className="imageContainer">
                            <img className="ideabiz-icon"
                              src={require("../../assets/Images/ideabiz1.png")}
                              alt="Ideabiz"
                            />
                          </div>
                        </li>}
                      </ul>
                    </>
                  }
                </div>

              </div>
            ) : null
          }

          {
            paymentGateways.cinetPay &&

            <div className={(paymentGateways.cinetPay & paymentGateways.paypal & paymentGateways.stripe) ? "center small" : (paymentGateways.cinetPay & !paymentGateways.paypal & !paymentGateways.stripe) || (paymentGateways.cinetPay & !paymentGateways.paypal & paymentGateways.stripe) ? "center no-border" : "center"}>


              <div className="paymentGatewayContainer">
                {
                  paymentGateways.cinetPay &&
                  <>
                    <h2 className="heading"><Translate textKey={'cinet_pay'} /></h2>
                    <ul className="gatewaysCinet">
                      <li onClick={cinetPayApi}>
                        <div className="cinetImageContainer" >
                          <img
                            src={require("../../assets/Images/cinetpay.png")}
                            alt="Cinet Pay"
                          />
                        </div>
                      </li>

                    </ul>
                  </>
                }
              </div>



            </div>
          }

          {
            paymentGateways.paypal &&

            <div className={(paymentGateways.cinetPay & paymentGateways.paypal & !paymentGateways.stripe) && (paymentGateways.stripe & paymentGateways.paypal & !paymentGateways.cinetPay) ? "right" : "right small"}>
              <PayPalScriptProvider
                options={{
                  "client-id": projectInfo?.projectConfig?.config?.PAYPAL_CLIENT_ID,
                  components: "buttons",
                  currency: selectedSubscriptionData?.currency,
                  vault: true,
                  intent: selectedSubscriptionData?.subscription_type_id === 3 || selectedSubscriptionData?.subscription_type_id === 4 ? "subscription" : null,
                }}
              >
                <PaypalButtonWrapper subscriptionData={selectedSubscriptionData} />

              </PayPalScriptProvider>
            </div>
          }

        </div>


      </div>
    </div>
  );
};

export default Payment;
