import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
// import { ReactComponent as LiveIcon } from "../../assets/Icons/live.svg";
// import { ReactComponent as InfoIcon } from "../../assets/Icons/info.svg";
import VideoJS from "../../../Components/VideoJsPlayer/VideoJS";
// import ScheduleDetailsModal from "../../Components/Modals/ScheduleDetailsModal";
// import LiveEpgContainer from "./LiveEpgContainer";
import { useDispatch, useSelector } from "react-redux";
import { getVideoSubscription } from "../../Subscription/service"
// import { fetchLiveChannels } from "./service";
import { getLiveScheduleData } from "../../../Redux/LiveScheduleData/liveScheduleDataSlice";
import { convertUTCToLocal, getCurrentTime, getCurrentTimeAndDate, getDiffInMinutes, imageUrlCheck, onVideoPlayFunction, timeConversion, translateText } from "../../../utils/utils";
import { getUserSubscription } from "../../../network/service";
import { getUserSubscriptionData } from "../../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
// import ContinueWithSubscriptionModal from "../../Components/Modals/ContinueWithSubscriptionModal";
// import parse from "html-react-parser";
import moment from "moment";
import { fetchFastChannels } from "../../LiveChannels/service";
import { getSelectedSchedule } from "../../../Redux/SelectedScheduleChannel/selectedScheduleChannelSlice";
import ContinueWithSubscriptionModal from "../../../Components/Modals/ContinueWithSubscriptionModal";
import { ReactComponent as RightArrow } from "../../../assets/Icons/pageDownArrow.svg";
import { Navigate, useNavigate } from "react-router-dom";
import Translate from "../../../Components/MultiLanguage/Translate";
import MainLoading from "../../../Components/Loading/MainLoading";

// import Loading from "../../Components/Loading/Loading";
let debounce = true;
var videoStarted = false;
let seconds = 60;
let prevTime;
const HomePagelive = ({ data }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const sessionId = useSelector((state) => state?.sessionId?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const scheduleModal = useSelector((state) => state?.scheduleModal?.value);
  const liveScheduleData = useSelector((state) => state?.liveSchedule?.value);
  const selectedSchedule = useSelector((state) => state?.selectedSchedule?.value);
  const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
  const [sliceCount, setSliceCount] = useState(550);
  const [channelData, setChannelData] = useState();
  const [livePlayer, setLivePlayer] = useState();
  const [isReadMore, setIsReadMore] = useState(true);
  const [timeLeft, setTimeLeft] = useState()
  const [liveUrl, setLiveUrl] = useState("")
  const [loading, setLoading] = useState(true)
  const [isContinueWithSubscriptionModal, setIsContinueWithSubscriptionModal] = useState(false);
  const [showBanner, setShowBanner] = useState(false)

  const navigate = useNavigate();
  const playerRef = useRef(null);
  const dispatch = useDispatch();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
    sessionId,
  };


  useEffect(() => {
    getLiveChannels()
    return () => {
      dispatch(
        getLiveScheduleData({
          liveSchedule: {},
        })
      );
      dispatch(
        getSelectedSchedule({
          selectedSchedule: {}
        })
      )
    };
  }, [])

  const getLiveChannels = async () => {
    dispatch(
      getLiveScheduleData({
        liveSchedule: data,
      })
    );
    dispatch(
      getSelectedSchedule({
        selectedSchedule: data?.shows[0]
      })
    )
  };

  useEffect(() => {
    setChannelData(null)
    setLivePlayer(null)
    setLiveUrl(null)
    if (liveScheduleData) {
      if (Object.entries(liveScheduleData).length > 0) {
        setLoading(false)
        if (selectedSchedule?.channel_id) {
          const filteredChannel = liveScheduleData?.shows?.filter((item) => selectedSchedule?.channel_id === item?.channel_id)
          if (filteredChannel) {
            const channelInfo = filteredChannel[0]
            setChannelData({ ...selectedSchedule, ...channelInfo });
          }
        }
      }
    }


    return () => localStorage.setItem("initialScheduleAdded", "false") //for showing first item from the schedule on nowplaying section
  }, [selectedSchedule, liveScheduleData]);
  useEffect(() => {
    if (channelData?.live_link) {
      checkSubscription()
      setTimeLeft(null)
      let diff = timeDifferenceHandler()
      if (diff > 60) {
        setTimeLeft(timeConversion(diff))
      } else if (diff > 0) {
        let leftTime = diff + "m"
        setTimeLeft(leftTime)
      }
    }
  }, [channelData])
  useEffect(() => {
    if (channelData?.start) {
      const interval = setInterval(() => {
        let diff = timeDifferenceHandler()
        if (diff > 60) {
          setTimeLeft(timeConversion(diff))
        } else if (diff > 0) {
          let leftTime = diff + "m"
          setTimeLeft(leftTime)
        }
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [channelData, timeLeft])

  const timeDifferenceHandler = () => {
    let time = getCurrentTimeAndDate()
    let currentTime = moment(time)
    let convertedEndTime = moment(channelData?.end)
    let differenceInMinutes = convertedEndTime.diff(currentTime, "minutes")
    return differenceInMinutes
  }



  useEffect(() => {
    if (liveUrl) {
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        poster: projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE,
        sources: [
          {
            src: `${liveUrl}`,
            type: "application/x-mpegURL",
          },
        ],
      };
      setLivePlayer(
        <VideoJS options={videoJsOptions} liveData={channelData} onReady={handlePlayerReady} />
      );
    }

  }, [liveUrl]);

  useEffect(() => {
    window.scroll(0, 0)
    fetchUserSubscriptionDetails();
  }, []);

  const fetchUserSubscriptionDetails = async () => {
    const userSubResponse = await getUserSubscription(appInfo);
    if (userSubResponse?.status === 200) {
      dispatch(
        getUserSubscriptionData({
          userSubscription: userSubResponse?.data,
        })
      );
    }
  };


  const checkSubscription = async () => {
    setIsContinueWithSubscriptionModal();

    const channelId = channelData?.channel_id;
    const appInfo = {
      projectDetails: projectInfo,
      accessToken: accessToken,
    };

    const videoSubRespone = await getVideoSubscription(appInfo, channelId, 'channel');
    const isSubscribedUser = matchSubscription(userSubscriptionData?.data, videoSubRespone?.data?.data)
    const fastChannelResponse = await fetchFastChannels(appInfo, channelId);
    const fastchannelInfo = fastChannelResponse?.data?.data[0]


    if (videoSubRespone?.data?.data.length == 0) {
      // setLiveUrl(channelData?.live_link)
      setLiveUrl(fastchannelInfo?.live_link);
      setShowBanner(false)
    } else {
      if (isSubscribedUser === true) {
        // setLiveUrl(channelData?.live_link)
        setLiveUrl(fastchannelInfo?.live_link);
        setShowBanner(false)
      } else {
        setIsContinueWithSubscriptionModal(true);
        setShowBanner(true)
      }
    }


  }

  const matchSubscription = (userSub, videoSub) => {
    const hasMatch = userSub.some((user) => videoSub.some((video) => user.sub_id === video.subscription_id));
    return hasMatch;
  };

  const liveShowHandler = (data) => {
    dispatch(
      getSelectedSchedule({
        selectedSchedule: data
      })
    )
  }
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("play", () => {
      let event = videoStarted == true ? "POP09" : "POP02";
      videoStarted = true;
      updateLiveAnalytics(
        channelData,
        event,
        player.currentTime()
      );
    });

    player.on("timeupdate", function (e) {
      let event = "POP03";

      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);
      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
      if (isSixty && debounce) {
        debounce = false;
        prevTime = intPlayedTime;
        updateLiveAnalytics(
          channelData,
          event,
          player.currentTime()
        );
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });

    player.on("pause", () => {
      let event = "POP04";
      updateLiveAnalytics(
        channelData,
        event,
        player.currentTime()
      );
    });
    player.on("ended", () => {
      let event = "POP05";
      updateLiveAnalytics(
        channelData,
        event,
        player.currentTime()
      );
    });


    player.on("dispose", () => {
      videoStarted = false;
      // player.pause();
    });
  };

  const updateLiveAnalytics = async (nowPlaying, event, time) => {
    const isLive = "1";
    const updateResponse = await onVideoPlayFunction(
      appInfo,
      nowPlaying,
      event,
      time,
      isLive
    );
  };

  const readMoreOrLessHandler = (type) => {
    if (type === "more") {
      if (channelData?.video_description) {
        setSliceCount(channelData?.video_description?.length);
        setIsReadMore(false);
      }
    } else {
      setSliceCount(550);
      setIsReadMore(true);
    }
  };

  return (
    <div className='homePageLiveContainer'>
      {isContinueWithSubscriptionModal && <ContinueWithSubscriptionModal
        setIsContinueWithSubscriptionModal={setIsContinueWithSubscriptionModal}
        channelData={channelData}
        appInfo={appInfo}
      />}
      <div className='wrapper'>
        <div className={showBanner ? "videoBannerContainer" : "videoContainertop"} style={showBanner ? { backgroundImage: `url(${projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE})` } : null}>
          <div className="videoContainer">
            {livePlayer}
          </div>
        </div>
        <div className='liveCardContainer' >
          <div className="itemsContainer">
            <div className="categoryNameContainer">
              <div className="content">
                <h1>{data?.category_name}</h1>
                <div className="exploreAll">
                  <div className="seeAll" onClick={() => { navigate("/live-channels") }}>
                    <Translate textKey={'explore_all'} />
                  </div>
                  <div className="rightArrow">
                    <RightArrow />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Swiper
            modules={[Navigation]}
            spaceBetween={0.5}
            slidesPerView={10}
            slidesPerGroup={2}
            navigation={true}
            watchSlidesProgress
            speed={1000}
            loop={false}
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 2
              },
              420: {
                slidesPerView: 3,
              },
              565: {
                slidesPerView: 4
              },
              640: {
                slidesPerView: 4,
              },
              768: {
                slidesPerView: 5,
              },
              980: {
                slidesPerView: 6,
              },
              1200: {
                slidesPerView: 8,
              },
              1400: {
                slidesPerView: 9
              },
              1600: {
                slidesPerView: 10,
              },
              1980: {
                slidesPerView: 11,
              },
              2200: {
                slidesPerView: 12,
              },
              2500: {
                slidesPerView: 13,
              }
            }}
          >
            {liveScheduleData?.shows?.map((item, index) => (
              <SwiperSlide key={index} >
                <div className='imageCard' key={index} onClick={() => { liveShowHandler(item) }}>
                  <img src={item?.placeholder} alt="" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

      </div>
    </div>
  )
}

export default HomePagelive