import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DetailScreenModal from "../../Screens/ShowDetailsScreen/DetailScreenModal";
import Loading from "../Loading/Loading";
import MainLoading from "../Loading/MainLoading";
import ShowCard from "../ShowCard/ShowCard";
import * as service from "./service";
import { useWindowWidth } from "@react-hook/window-size";
import { rowItemCount } from "../../utils/utils";
import EventDetailsScreenModal from "../../Screens/EventDetailsScreenModal/EventDetailsScreenModal";
import useScrollPosition from "../ScrollPosition/useScrollPosition";
import Translate from "../MultiLanguage/Translate";
import MagazineCard from "../MagazineCard/MagazineCard";

const CommonPage = () => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const isModalOpen = useSelector((state) => state?.moreInfoOther?.value);
  const isEventMoreInfo = useSelector((state) => state?.eventMoreInfo?.value);

  const [shows, setShows] = useState([]);
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [img, setImg] = useState(true);
  const [rowItemsClass, setRowItemsClass] = useState("");
  const [showInvisibleShow, setShowInvisibleShow] = useState(false);
  const [sliceCount,setSliceCount] = useState(20)
  const windowSize = useWindowWidth();
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const [loading, setLoading] = useState(true);
  const careers = params?.get("career");
  const type = location?.state?.type;
  const navigate = useNavigate();
  const containerRef = useRef(null)
  const scrollPosition = useScrollPosition();

  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };
  useEffect(() => {
    window.scroll(0, 0);
    fetchCategoryDetails();
  }, [location]);


  useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);

  useEffect(()=>{
    if(!loading && sliceCount<shows?.shows?.length){
      lazyLoad()
    }
  },[loading,scrollPosition,shows])

  useEffect(() => {
    if(windowSize>980){
      if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT){
        setRowItemsClass(rowItemCount(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT))
      }else{
        setRowItemsClass("rowCount4")
      }
    }else{
      setRowItemsClass("")
    }
    if(shows){
      if(Object.entries(shows)?.length>0){
        if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT==="3" && windowSize>980){
          if(shows?.shows?.length%3!==0){
            setShowInvisibleShow(true)
          }
        }else if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT==="4" && windowSize>980){
          if(shows?.shows?.length%4!==0){
            setShowInvisibleShow(true)
          }
        }else if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT==="5" && windowSize>980){
          if(shows?.shows?.length%5!==0){
            setShowInvisibleShow(true)
          }
        }else if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT==="6" && windowSize>980){
          if(shows?.shows?.length%6!==0){
            setShowInvisibleShow(true)
          }
        }else if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT==="7" && windowSize>980){
          if(shows?.shows?.length%7!==0){
            setShowInvisibleShow(true)
          }
        }
        else if(projectInfo?.projectConfig?.config?.WEBSITE_ROW_ITEM_COUNT==="8" && windowSize>980){
          if(shows?.shows?.length%8!==0){
            setShowInvisibleShow(true)
          }
        }
        else{
          setShowInvisibleShow(false)
        }
    
        if(shows?.shows?.length===1){
          setShowInvisibleShow(true)
        }
      }
    }

  }, [projectInfo,windowSize,shows]);

  const fetchCategoryDetails = async () => {
    setLoading(true);
    try {
      const itemResponse = await service.getAllShowsOfCategory(
        appInfo,
        careers
      );
      if (itemResponse?.status === 200) {
        setShows(itemResponse?.data?.data);
        setImg(false);
        setLoading(false);
      }
    } catch (err) {
      // toast.error(err?.response?.data?.message, {
      //   position: "top-center",
      // });
      setShows([]);
      setLoading(false);
    }
  };
  const lazyLoad = () => {
    const containerHeight = containerRef?.current?.clientHeight;
    if(scrollPosition + 2200 >= containerHeight){
      setSliceCount(sliceCount+20)
    }
  }
  if (loading) {
    return <MainLoading />;
  }


  return (
    <>
      <div className="commonPage" ref={containerRef}>
        {isModalOpen?.isModal && !isModalOpen?.isFromSearch && (
          <DetailScreenModal />
        )}
        {
        isEventMoreInfo?.isModal&&
      <EventDetailsScreenModal/>
      }
        {(shows?.shows?.length === 0) | (Object.entries(shows).length === 0) ? (
          <h1
            style={{
              color: "#E72A31",
              display: "flex",
              justifyContent: "center",
              padding: "60px 0",
            }}
          >
            <Translate textKey={'no_data_found'} />
          </h1>
        ) : (
          <div className="wrapper">
            {shows?.image ? (
              <div className="bgImageContainer">
                <div className="top inside">
                  <h1 className="heading">{shows?.category_name}</h1>
                  <p className="desc">{shows?.synopsis}</p>
                </div>
                <img src={shows?.image} alt="BgImage" />

                <div className="bottomGradient" />
              </div>
            ) : (
              <div className="top">
                <h1 className="heading">{shows?.category_name}</h1>
                <p className="desc">{shows?.synopsis}</p>
              </div>
            )}

            <div className="bottom">
              <div className="itemsContainer">
                {shows?.category_name!=="Magazines"?
                  (shows?.shows?.slice(0,sliceCount)?.map((item, index) => (
                    <div
                    className={
                      thumbnailOrientation === `PORTRAIT` 
                        ? `items portrait ${rowItemsClass}`
                        : `items landscape ${rowItemsClass}`
                    }
                    >
                      <ShowCard
                        data={item}
                        key={index}
                        imageUrl={img}
                        season={false}
                        metaData={true}
                        cardHover={true}
                        modalPageNavigation={false}
                        categoryName={shows?.category_name}
                      />
                    </div>
                   ))):(
                  shows?.shows?.slice(0,sliceCount)?.map((item, index) => (    
                    <div
                    className={
                    shows?.category_name==="Magazine"
                        ? `items portrait ${rowItemsClass}`
                        : `items landscape ${rowItemsClass}`
                    }
                    >
                      <MagazineCard
                      data={item}
                      metaData={true}
                      cardHover={true}
                      />
                    </div>
                  ))
                  )
                }

                 {showInvisibleShow &&
                  shows?.shows?.slice(0, 1).map((item, index) => (
                    <div
                      className={
                        thumbnailOrientation === `PORTRAIT`
                          ? `items portrait hidden ${rowItemsClass}`
                          : `hidden items landscape ${rowItemsClass}`
                      }
                    >
                  <ShowCard
                    data={item}
                    key={index}
                    imageUrl={true}
                    season={false}
                    metaData={true}
                    modalPageNavigation={false}
                    categoryName={shows?.category_name}
                  />
                </div>
              ))}
              </div>
            </div>
          </div>
        )}
      </div>
      
    </>
  );
};

export default CommonPage;
