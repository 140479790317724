import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Zoom from "@mui/material/Zoom";
import { useDispatch, useSelector } from "react-redux";
import { getMoreInfo } from "../../Redux/MoreInfo/moreInfoSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getShowDetails, getSimilarVideos } from "./service";
import { ReactComponent as FacebookIcon } from "../../assets/Icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/Icons/twitter.svg";
import { ReactComponent as ClipBoard } from "../../assets/Icons/clipBoard.svg";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { checkOperatingSystem, imageUrlCheck } from "../../utils/utils";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { ReactComponent as AddListIcon } from "../../assets/Icons/addToWatchlist.svg";
import { ReactComponent as RemoveFromWatchListIcon } from "../../assets/Icons/removeFromWatchlist.svg";
import { ReactComponent as CloseIcon } from "../../assets/Icons/cross.svg";
import { ReactComponent as SoundOn } from "../../assets/Icons/speaker.svg";
import { ReactComponent as SoundOff } from "../../assets/Icons/speakerOff.svg";
import { ReactComponent as ShareIcon } from "../../assets/Icons/share.svg";

import parse from "html-react-parser";
import EpisodeContainer from "./Components/EpisodeContainer";
import MainLoading from "../../Components/Loading/MainLoading";
import SimiliarShowsCard from "../../Components/SimiliarShowCard/SimiliarShowsCard";
import { authenticate, getPlayerToken, getUserSubscription, updateWatchlistData } from "../../network/service";
import VideoJS from "../../Components/VideoJsPlayer/VideoJS";
import { useWindowWidth } from "@react-hook/window-size";
import { getAccessToken } from "../../Redux/AToken/ATokenSlice";
import { getUser } from "../../Redux/UserSlice/UserSlice";
import { getMoreInfoOther } from "../../Redux/MoreInfo/moreInfoOtherSlice";
import { getUserSubscriptionData } from "../../Redux/UserSubscriptionDetails/userSubscriptionSlice";
import TrailerModal from "../../Components/Modals/TrailerModal";
import Translate from "../../Components/MultiLanguage/Translate";

let debounce = true;
let seconds = 60;
let prevTime;
let videoPlaying = false;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom direction="down" ref={ref} {...props} />;
});
const DetailScreenModal = ({ fetchHomeData, modalShow }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const isMoreInfoOther = useSelector((state) => state?.moreInfoOther?.value);

  const userSubscriptionData = useSelector((state) => state?.userSubscription?.value);
  const isMoreInfo = useSelector((state) => state?.overlay?.value);
  const [showDetails, setShowDetails] = useState({});
  const [similarVideos, setSimilarVideos] = useState([]);
  const [playerToken, setPlayerToken] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [watchlistStatus, setWatchlistStatus] = useState();
  const [selectedVideoDetails, setSelectedVideoDetails] = useState();
  const [isSubscribedUser, setIsSubscribedUser] = useState();
  const [isFreeVideo, setIsFreeVideo] = useState();
  const [subscribeButton, setSubscribeButton] = useState();
  const [videoPlayer, setVideoPlayer] = useState();
  const [videoStarted, setVideoStarted] = useState(false);
  const [isMute, setIsMute] = useState(true);
  const [trailerModal, setTrailerModal] = useState(false);
  const [categories, setCategories] = useState("");
  const [open, setOpen] = useState(false);
  const [pageURL, setPageURL] = useState("");
  const [isShareActive, setIsShareActive] = useState(false);

  const [adUrl, setAdUrl] = useState("");
  const windowSize = useWindowWidth();
  const playerRef = useRef(null);
  const scrollRef = useRef(null);
  const shareRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const { showId } = useParams();
  const navigate = useNavigate();
  let showUniqueId = isMoreInfoOther?.showId ? isMoreInfoOther?.showId : showId;
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };


  useEffect(() => {
    setPageURL(window.location.href);
    return () => {
      handleReset();
    };
  }, []);
  useEffect(() => {
    if (location?.pathname === "/home") {
      handleClose();
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (isMoreInfoOther?.showId) {
      showUniqueId = isMoreInfoOther?.showId;
    }
  }, [isMoreInfoOther?.showId]);

  useEffect(() => {
    if (showUniqueId) {
      setOpen(true);
      if (showUniqueId !== "null" && showUniqueId !== "undefined") {
        fetchShowDetails();
      }
      if (location?.state?.showId) {
        fetchSimilarVideos(location?.state?.showId);
      }
    }
  }, [showUniqueId]);

  useEffect(() => {
    if (isMoreInfo) {
      setTimeout(() => (document.body.className = ""), 580);
    }
  }, [isMoreInfo]);

  useEffect(() => {
    if (showDetails) {


      if (!loading) {
        executeScroll();
      }
      if (showDetails?.categories) {
        let categoryNames;
        showDetails?.categories?.map((item) => {
          categoryNames = categoryNames ? categoryNames + item?.category_name + "," : item?.category_name + ",";
        });
        setCategories(categoryNames);
      }
      if (showDetails?.watchlist_flag === 1) {
        setWatchlistStatus("added");
      } else {
        setWatchlistStatus("removed");
      }
      if (showDetails?.teaser_flag === 1) {
        if (checkOperatingSystem() !== "iPhone") {
          fetchPlayerToken();
        }
      }
      if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "true") {
        if (showDetails?.single_video === 1) {
          setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]);
          if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
            if (
              (showDetails?.videos && showDetails?.videos[0]?.subscriptions?.length > 0) ||
              (showDetails?.subscriptions && showDetails?.subscriptions?.length > 0)
            ) {
              let isSubscribedUser;
              if (showDetails?.subscriptions?.length > 0) {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                    : false;
              } else {
                isSubscribedUser =
                  userSubscriptionData?.data?.length > 0
                    ? subscriptionCheck(
                      userSubscriptionData?.data,
                      showDetails?.videos && showDetails?.videos[0]?.subscriptions
                    )
                    : false;
              }

              if (isSubscribedUser === true) {
                setIsSubscribedUser(true);
                setSubscribeButton(false);
              } else {
                setIsSubscribedUser(false);

                if (showDetails?.videos[0]?.free_video === true) {
                  // for inital free video checking for showing button name "subscribe" ,"play Now"
                  setSubscribeButton(false);

                  setIsFreeVideo(true);
                } else {
                  setSubscribeButton(true);

                  setIsFreeVideo(false);
                }
              }
            } else {
              setIsSubscribedUser(true);
              setSubscribeButton(false);
            }
          } else {
            setIsSubscribedUser(true);
            setSubscribeButton(false);
          }
        } else {
          if (showDetails?.videos && showDetails?.videos[0]?.videos && showDetails?.videos[0]?.videos[0]) {
            let video = showDetails?.videos[0]?.videos[0];
            setSelectedVideoDetails(showDetails?.videos[0]?.videos[0]);
            if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
              if (video?.subscriptions?.length > 0 || showDetails?.subscriptions?.length > 0) {
                let isSubscribedUser;
                if (showDetails?.subsriptions?.length > 0) {
                  isSubscribedUser =
                    userSubscriptionData?.data?.length > 0
                      ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                      : false;
                } else {
                  isSubscribedUser =
                    userSubscriptionData?.data?.length > 0
                      ? subscriptionCheck(userSubscriptionData?.data, video?.subscriptions)
                      : false;
                }

                if (isSubscribedUser === true) {
                  setIsSubscribedUser(true);
                  setSubscribeButton(false);
                } else {
                  setIsSubscribedUser(false);

                  if (video?.free_video === true) {
                    // for inital free video checking for showing button name "subscribe" ,"play Now"
                    setSubscribeButton(false);

                    setIsFreeVideo(true);
                  } else {
                    setSubscribeButton(true);

                    setIsFreeVideo(false);
                  }
                }
              } else {
                setIsSubscribedUser(true);
                setSubscribeButton(false);
              }
            } else {
              setIsSubscribedUser(true);
              setSubscribeButton(false);
            }
          } else {
            let video = showDetails?.videos && showDetails?.videos[0];
            setSelectedVideoDetails(video);
            if (projectInfo?.projectConfig?.config?.SUBSCRIPTION_REQUIRED === "true") {
              if (video?.subscriptions?.length > 0 || showDetails?.subscriptions?.length > 0) {
                let isSubscribedUser;
                if (showDetails?.subscriptions?.length > 0) {
                  isSubscribedUser =
                    userSubscriptionData?.data?.length > 0
                      ? subscriptionCheck(userSubscriptionData?.data, showDetails?.subscriptions)
                      : false;
                } else {
                  isSubscribedUser =
                    userSubscriptionData?.data?.length > 0
                      ? subscriptionCheck(userSubscriptionData?.data, video?.subscriptions)
                      : false;
                }

                if (isSubscribedUser === true) {
                  setIsSubscribedUser(true);
                  setSubscribeButton(false);
                } else {
                  setIsSubscribedUser(false);

                  if (video?.free_video === true) {
                    // for inital free video checking for showing button name "subscribe" ,"play Now"
                    setSubscribeButton(false);

                    setIsFreeVideo(true);
                  } else {
                    setSubscribeButton(true);

                    setIsFreeVideo(false);
                  }
                }
              } else {
                setIsSubscribedUser(true);
                setSubscribeButton(false);
              }
            } else {
              setIsSubscribedUser(true);
              setSubscribeButton(false);
            }
          }
        }
      } else if (projectInfo?.projectConfig?.config?.REGISTRATION_MANDATORY === "false") {
        setSubscribeButton(false);
        if (userSubscriptionData?.login_needed === false) {
          setIsFreeVideo(true);
        } else {
          setIsFreeVideo(false);
        }
        if (showDetails?.single_video === 1) {
          setSelectedVideoDetails(showDetails?.videos[0]);
        } else {
          if (showDetails?.videos && showDetails?.videos[0]?.videos) {
            setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]?.videos[0]);
          } else {
            setSelectedVideoDetails(showDetails?.videos && showDetails?.videos[0]);
          }
        }
      }
    }
  }, [showDetails]);


  useEffect(() => {
    setVideoPlayer(null);
    if (videoUrl) {
      const videoJsOptions = {
        autoplay: false,
        controls: false,
        muted: isMute,
        responsive: true,
        fluid: true,
        loop: true,
        controlBar: {
          pictureInPictureToggle: false,
        },
        sources: [
          {
            src: `${videoUrl}`,
            type: "application/x-mpegURL",
          },
        ],
        // poster:require("../../../images/thumb.png")
      };

      setVideoPlayer(<VideoJS options={videoJsOptions} onReady={handlePlayerReady} adUrl={null} />);
    }
  }, [videoUrl, isMute]);
  const fetchPlayerToken = async (videoId) => {
    let arr = showDetails?.teaser?.split("/").reverse();
    const playerTokenResponse = await getPlayerToken(appInfo, arr[1], videoId);
    if (playerTokenResponse?.status === 200) {
      setPlayerToken(playerTokenResponse?.data?.data);
      setVideoUrl(
        "https://api.gizmott.com/api/v1/playlistV2/playlist.m3u8?id=" +
        arr[1] +
        "&token=" +
        playerTokenResponse?.data?.data +
        "&type=trailer&pubid=" + appInfo?.projectDetails?.projectConfig?.pubid
      );
      return playerTokenResponse?.data?.data;
    }
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on("loadedmetadata", () => {
      setVideoStarted(true);
      player.play();
    });
    player.on("timeupdate", () => {
      videoPlaying = true;
    });
    player.on("dispose", () => {
      videoPlaying = false;
    });
  };

  const updateWatchlist = async (showUniqueId, flag) => {
    try {
      const response = await updateWatchlistData(appInfo, showUniqueId, flag);

      if (response?.status === 200) {
        if (flag === 1) {
          setWatchlistStatus("added");
        } else {
          setWatchlistStatus("removed");
        }
        fetchHomeData();
      }
    } catch (err) { }
  };

  const fetchShowDetails = async (show_id) => {
    try {
      setLoading(true);
      const showDetailsResponse = await getShowDetails(appInfo, showUniqueId);
      if (showDetailsResponse?.status === 200) {
        setShowDetails(showDetailsResponse?.data?.data);
        setLoading(false);

        if (!location?.state?.showId) {
          fetchSimilarVideos(showDetailsResponse?.data?.data?.show_id);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };



  const fetchSimilarVideos = async (id) => {
    const similarVideosResponse = await getSimilarVideos(appInfo, id);
    if (similarVideosResponse?.status === 200) {
      setSimilarVideos(similarVideosResponse?.data?.data);
    }
  };

  const addToWatchlist = (id) => {
    if (user) {
      updateWatchlist(id, 1);
    } else {
      navigate("/login", {
        state: { path: "/show-details/" + showUniqueId, showId: showUniqueId },
      });
    }
  };
  const removeFromWatchlist = (id) => {
    updateWatchlist(id, 0);
  };

  const handleClose = () => {
    document.body.className = "hideScroll";
    handleReset();
    setOpen(false);

    if (isMoreInfoOther?.isModal) {
      if (isMoreInfoOther?.isFromSearch) {
        if (location?.state?.isFromHome) {
          navigate("/home");
        }
        setTimeout(() => {
          dispatch(
            getMoreInfoOther({
              isModal: false,
              showId: null,
              isFromSearch: false,
            })
          );
        }, 600);
      } else {
        setTimeout(() => {
          dispatch(
            getMoreInfoOther({
              isModal: false,
              showId: null,
            })
          );
        }, 600);
      }
    } else {
      navigate("/home");
      setTimeout(() => {
        dispatch(
          getMoreInfo({
            moreInfo: false,
          })
        );
      }, 500);
    }
    setTimeout(() => {
      setShowDetails(null);
    }, 600);
  };

  const handleReset = () => {
    setVideoPlayer(null);
    setIsMute(false);
    setVideoStarted(false);
  };

  const volumeButtonHandler = (type) => {
    if (type === "unmute") {
      setIsMute(false);

      playerRef?.current?.muted(false);
    } else {
      setIsMute(true);
      playerRef?.current?.muted(true);
    }
  };

  const trailerHandler = (flag) => {
    if (flag === "close") {
      setTrailerModal(false);
      if (checkOperatingSystem() !== "iPhone") {
        playerRef?.current?.play();
      }
    } else {
      setTrailerModal(true);
      if (checkOperatingSystem() !== "iPhone") {
        playerRef?.current?.pause();
      }
    }
  };

  const executeScroll = () => {
    return scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const subscriptionCheck = (userSub, videoSub) => {
    const hasMatch = userSub.some((user) => videoSub.some((video) => user.sub_id === video.subscription_id));
    return hasMatch;
  };

  const playVideo = async (videoDetails, watchDuration) => {
    navigate(`/videos/${videoDetails?.vanity_url}`, {
      state: {
        isSubscribedUser,
        videoDetails,
        showDetails,
        prevLocation: location?.search ? location?.pathname + location?.search : location?.pathname,
      },
    });
  };

  const redirectToLogin = () => {
    dispatch(
      getUser({
        user: null,
      })
    );
    localStorage.removeItem("userId");
    tokenAuthenticate();
    navigate("/login", {
      state: { path: location?.pathname, showId: showUniqueId },
    });
  };

  const tokenAuthenticate = async () => {
    const response = await authenticate(appInfo);
    if (response?.status === 200) {
      dispatch(
        getAccessToken({
          accessToken: response?.data?.token,
        })
      );
    }
  };

  const handleSubscribe = (data) => {
    if (user) {
      localStorage.setItem("selectedSubId", data?.subscription_id);
      localStorage.setItem("selectedAmount", data?.price);
      localStorage.setItem("showId", showDetails?.show_id);
      localStorage.setItem("vanityUrl", showUniqueId);
      navigate("/payment", { state: { subscription: { ...data, is_coupon: false } } });
    } else {
      handleClose();
      navigate("/login", {
        state: { path: "/show-details/" + showUniqueId, showId: showUniqueId },
      });
    }
  };


  const watchVideoHandler = async (videoDetails, directSubscribe, directSubscribeData) => {
    setSelectedVideoDetails({ ...videoDetails, showId: showUniqueId });
    playVideo(videoDetails);
  };

 
  if (loading) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        transitionDuration={600}
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        fullScreen={windowSize > 980 ? false : true}
      >
        <DialogContent sx={{ p: 0 }} dividers={false}>
          <MainLoading />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      transitionDuration={600}
      scroll={"body"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      fullScreen={windowSize > 980 ? false : true}
    >
      <DialogContent sx={{ p: 0 }} dividers={false}>
        <div className="detailsScreenModal" ref={scrollRef} onClick={() => setIsShareActive(false)}>
          {trailerModal && (
            <TrailerModal
              teaser={showDetails?.teaser}
              showDetails={showDetails}
              trailerHandler={trailerHandler}
              appInfo={appInfo}
            />
          )}
         
          <div className={videoStarted ? "imgVideoContainer noHeight" : "imgVideoContainer"}>
            <div className="closeButton">
              <button onClick={() => handleClose()}>
                <div className="icon">
                  <CloseIcon />
                </div>
              </button>
            </div>
            {videoPlayer && videoPlayer}
            {imageUrlCheck(showDetails?.logo_thumb) ? (
              <img className={videoStarted ? "hide" : "show"} src={showDetails?.logo_thumb} alt="Thumbnail" />
            ) : (
              <img
                className={videoStarted ? "hide" : "show"}
                src={`${process.env.REACT_APP_IMAGE_URL}${showDetails?.logo_thumb}`}
                alt="Thumbnail"
              />
            )}

            <div className="showActions">
            
              {showDetails?.image_title ? imageUrlCheck(showDetails?.image_title) ? (
                <img src={showDetails?.image_title} className="title" alt="Thumbnail" />
              ) : (
                <img src={`${process.env.REACT_APP_SHOW_IMAGE_URL}${showDetails?.image_title}`} alt="Thumbnail" className="title" />
              ) : <h1 className="title">{showDetails?.show_name}</h1>}
              <div className="buttons">
                <div className="left">
                  {/* {subscribeButton ? (
                    <button className="subscribe" onClick={() => watchVideoHandler(selectedVideoDetails)}>
                      <span><Translate textKey={'subscribe'} /></span>
                    </button>
                  ) : (
                    <button className="play" onClick={() => watchVideoHandler(selectedVideoDetails)}>
                      <div className="icon">
                        <PlayIcon />
                      </div>
                      <span><Translate textKey={'play'} /></span>
                    </button>
                  )} */}                
                    {
                    <button className="watchnow" onClick={() => watchVideoHandler(selectedVideoDetails)}>
                      <div className="icon">
                        <PlayIcon />
                      </div>
                      <span><Translate textKey={'watch_now'} /></span>
                    </button>
                  }
                  {watchlistStatus === "added" ? (
                    <button className="addToList" onClick={() => removeFromWatchlist(showDetails?.show_id)}>
                      <span className="tooltip remove"><Translate textKey={'remove_from_mylist'} /> </span>
                      <RemoveFromWatchListIcon />
                    </button>
                  ) : (
                    <button className="addToList" onClick={() => addToWatchlist(showDetails?.show_id)}>
                      <span className="tooltip"> <Translate textKey={'add_to_mylist'} /></span>
                      <AddListIcon />
                    </button>
                  )}
                  {projectInfo?.projectConfig?.config?.SHOW_SHARE_BUTTON === "true" && (
                    <div className="share">
                      <button
                        className="shareButton"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsShareActive(!isShareActive);
                        }}
                      >
                        <ShareIcon />
                      </button>
                      <div className={isShareActive ? "active shareContainer" : "shareContainer"} ref={shareRef}>
                        <div className="facebookContainer" onClick={() => setIsShareActive(false)}>
                         
                          <FacebookShareButton url={pageURL} quote={"Share"} className="facebook">
                            Facebook
                            <div className="icon">
                              <FacebookIcon />
                            </div>
                          </FacebookShareButton>
                        
                        </div>
                        <div className="twitterContainer" onClick={() => setIsShareActive(false)}>
                          <TwitterShareButton url={pageURL} className="twitter">
                            Twitter
                            <div className="icon">
                              <TwitterIcon />
                            </div>
                          </TwitterShareButton>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="right">
                  {videoStarted && (
                    <div className="volumeButton">
                      {isMute ? (
                        <button onClick={() => volumeButtonHandler("unmute")}>
                          <SoundOff />
                        </button>
                      ) : (
                        <button onClick={() => volumeButtonHandler("mute")}>
                          <SoundOn />
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="bottomVignette"></div>
          </div>
          <div className="info">
            <div className="metaData">
              <div className="left">
                <div className="top">
                  <span className="duration">{showDetails?.duration_text}</span>
                  <span className="year">{showDetails?.year}</span>
                  {showDetails?.rating &&

                    <span className="rating">{showDetails?.rating}</span>
                  }
                  <div className="audio">
                    <span className="tooltip"><Translate textKey={'audio_language'} /></span>
                    {showDetails?.audio_language_name}
                  </div>
                </div>
                {showDetails?.synopsis ||
                  !isSubscribedUser ||
                  selectedVideoDetails?.free_video === true ||
                  showDetails?.single_video === 1 ||
                  showDetails?.single_video === 0 ? (
                  <div className="bottom">
                    {showDetails?.synopsis && (
                      <p className="description">{parse(showDetails?.synopsis?.split("\n")?.join("<br>"))}</p>
                    )}
                    {!isSubscribedUser || selectedVideoDetails?.free_video === true ?
                      (showDetails?.subscriptions?.length > 0 || selectedVideoDetails?.free_video === true ? (
                        <div className="subscriptionContainer">
                          {showDetails?.subscriptions?.length > 0 &&
                            showDetails?.subscriptions?.map((item, index) => (
                              <button onClick={() => handleSubscribe(item)}>
                                <span>{item?.subscription_text}</span>
                              </button>
                            ))}
                          {showDetails?.single_video === 1 && selectedVideoDetails?.free_video === true && (
                            <button className="free" onClick={() => watchVideoHandler(selectedVideoDetails)}>
                              <span>
                                <Translate textKey={'free'} />
                              </span>
                            </button>
                          )}
                        </div>
                      ) : null) : null

                    }
                  </div>
                ) : null}
              </div>

              <div className="right">
                {showDetails?.cast?.length > 0 ? (
                  <div className="cast">
                    <span className="heading"><Translate textKey={'cast'} /></span>
                    {showDetails?.cast?.map((item, index) => (
                      <span onClick={() => window.open(item?.imdb_link)} key={index}>
                        {item?.name}
                        {index + 1 !== showDetails?.cast?.length && ","}
                      </span>
                    ))}
                  </div>
                ) : showDetails?.show_cast && (
                  <div className="cast">
                    <span className="heading">Cast:</span>
                    <span>{showDetails?.show_cast}</span>
                  </div>
                )}
                {showDetails?.crew?.length > 0 && (
                  <div className="crew">
                    <span className="heading"><Translate textKey={'crew'} />:</span>
                    {showDetails?.crew?.map((item, index) => (
                      <span onClick={() => window.open(item?.imdb_link)} key={index}>
                        {item?.name}
                        {index + 1 !== showDetails?.crew?.length && ","}
                      </span>
                    ))}
                  </div>
                )}
                {showDetails?.categories?.length > 0 && (
                  <div className="categories">
                    <span className="heading"><Translate textKey={'categories'} />:</span>
                    {showDetails?.categories?.map((item, index) => (
                      <span onClick={() => navigate(`/category/${item?.key}?career=${item?.key}`)} key={index}>
                        {item?.category_name}
                        {index + 1 !== showDetails?.categories?.length && ","}
                      </span>
                    ))}
                  </div>
                )}
                {showDetails?.director && (
                  <div className="director">
                    <span className="heading"> <Translate textKey={'director'} />:</span>
                    <span>{showDetails?.director}</span>
                  </div>
                )}
              </div>
            </div>
            {showDetails?.single_video === 0 && (
              <EpisodeContainer showDetails={showDetails} watchVideoHandler={watchVideoHandler} />
            )}
            {showDetails?.teaser_flag === 1 && (
              <div className="showTrailerContainer">
                <h1 className="heading"><Translate textKey={'trailer'} /></h1>
                <div className="imageContainer" onClick={() => trailerHandler("open")}>
                  {imageUrlCheck(showDetails?.logo_thumb) ? (
                    <img src={showDetails?.logo_thumb} alt="Thumbnail" />
                  ) : (
                    <img src={`${process.env.REACT_APP_IMAGE_URL}${showDetails?.logo_thumb}`} alt="Thumbnail" />
                  )}
                  <div className="playIconContainer">
                    <PlayIcon />
                  </div>
                </div>
              </div>
            )}
            {similarVideos?.length > 0 && (
              <div className="similiarShowsContainer">
                <h1 className="heading"><Translate textKey={'more_like_this'} /></h1>
                <div className="cards">
                  {similarVideos?.map((item, index) => (
                    <div className="show">
                      <SimiliarShowsCard data={item} handleReset={handleReset} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleClose}>Subscribe</Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default DetailScreenModal;
