import React, { useEffect, useState } from 'react'
import { CircularProgressbar,buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate} from 'react-router-dom';
var timerSeconds = 10
const Autoplay = ({episodes,setIsAutoplayView}) => {
    const [timer,setTimer] = useState(10)
    const navigate = useNavigate()
    

    useEffect(()=>{
        const interval = setInterval(() => {
                timerSeconds--
                if(timerSeconds>=0){
                    setTimer((prev)=>prev-1)
                }else{
                    clearInterval(interval)
                }
           
        }, 1000);

        return ()=>{
            clearInterval(interval)
            resetValues()
        }
    },[])

    useEffect(()=>{
        if(timer===0){
            playNextEpisodeHandler()
        }
    },[timer])

    const playNextEpisodeHandler = () =>{
        setIsAutoplayView(false)
        setTimer(10)
        timerSeconds = 10
        navigate(`/videos/${episodes[0]?.vanity_url}`,{state: {videoDetails:episodes[0] }})
    }

    const resetValues = () => {
        setTimer(10)
        timerSeconds = 10

    }

  return (
    <div className='autoplayComponent'>
        <div className="content">
            <div className="progressBar">
            <CircularProgressbar 
                value={Math.round((10-timer)*10)} 
                text={`${timer}s`}
                styles={buildStyles({
                    pathColor: `var(--button-color)`,
                    textColor:  `var(--button-color)`,
                })} 
            />
            </div>
            <span className="timer">Next video will start in {timer}s</span>
        </div>
    </div>
  )
}

export default Autoplay