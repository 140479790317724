import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../assets/Icons/play.svg";
import { appInfo, fetchEventDetails, fetchEventSubscriptionDetails } from "../../network/service";
import { fetchWatchlistShows } from "../../Screens/MyList/service";
import { getMyListData } from "../../Redux/MyList/MyListSlice";
import { getMoreInfo } from "../../Redux/MoreInfo/moreInfoSlice";
import { imageUrlCheck, secondsToMinutes } from "../../utils/utils";
import Translate from "../MultiLanguage/Translate";

const MagazineCard = ({ data,url, metaData, imageUrl, type,cardHover,modalPageNavigation,showCount,categoryData }) => {
  const projectInfo = useSelector((state) => state?.projectInfo?.value);
  const accessToken = useSelector((state) => state?.accessToken?.value);
  const user = useSelector((state) => state?.user?.value);
  const userSubscriptionData = useSelector(
    (state) => state?.userSubscription?.value
  );
  const [thumbnailOrientation, setThumbnailOrientation] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isCardHovered, setIsCardHovered] = useState(false);
  const [imageURL,setImageURL] = useState(projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE)

  const [isPdfOpen, setIsPdfOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appInfo = {
    projectDetails: projectInfo,
    accessToken: accessToken,
  };


  useEffect(() => {
    if (
      projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT"
    ) {
      setThumbnailOrientation("PORTRAIT");
    } else {
      setThumbnailOrientation("LANDSCAPE");
    }
  }, [projectInfo]);
  
  const fetchWatchlist = async () => {
    const response = await fetchWatchlistShows(appInfo);
    if (response?.status === 200) {
      dispatch(
        getMyListData({
          myList: response?.data?.data,
        })
      );
    }
  };


  const replaceImage = (error) => {
    error.target.src = projectInfo?.projectConfig?.config?.PLACEHOLDER_IMAGE
  }
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (isCardHovered) {
      setIsCardHovered(false);
    }
  };
  const handleCardHover = () => {
    setIsCardHovered(true);
  };
  return (
    <div
      className={cardHover ? `magazineCardContainer cardHover ` : `magazineCardContainer`}
      title={data?.show_name}
      >
      <div className="showMoreOverlay" onClick={()=>window.open(url,'_blank')}>
        <h2><Translate textKey={'show_all'} /> ( {showCount} )</h2>
      </div>
     
      <div className={`square ${isCardHovered ? 'cardHovered' : ''}`} onClick={() => window.open(data?.url, '_blank')} onMouseEnter={handleCardHover}>
        <div className="imageContainer">  
          <img
            src={imageURL}
            key={data?.show_id}
            onError={(e) => replaceImage(e)}
            onLoad={(e) => setImageURL(data?.logo)}
            alt="ShowImage"
          />
        </div> 
        <div className="metaData">
         <h1 className="title">{data?.show_name}</h1>
         {/* <h1 className="synopsis">{data?.synopsis}</h1> */}
         <div className="buttons">
            <button onClick={()=>window.open(url,'_blank')}>  
                 Download
            </button>
            </div>
        </div>
      </div>
     
    </div>
    
  );
};

export default MagazineCard ;
