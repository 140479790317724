import React, { useEffect, useState } from "react";
import { ReactComponent as PlayIcon } from "../../../assets/Icons/play.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import 'swiper/css/pagination';
import { ReactComponent as PremiumIcon } from "../../../assets/Icons/premium.svg";

import { EffectFade, Thumbs, Autoplay,Pagination } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import PlayerShowCard from "../../../Components/PlayerShowCard/PlayerShowCard";
import { getRandomItem } from "../../../utils/utils";
import { log } from "video.js";

const Featured = ({ data , fontClass }) => {
 
  return (
    <div className="featured">
       <Swiper
        slidesPerView={1}
        watchSlidesProgress
        effect={"fade"}
        spaceBetween={0}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        
        className="mySwiper"
        modules={[EffectFade, Autoplay]}
        // allowSlideNext={false}
        // allowSlidePrev={false}
      >
        {data?.shows?.map((item, index) => (
          <SwiperSlide key={index}>
           <PlayerShowCard data={item} fontClass={fontClass} />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <PlayerShowCard data={randomShow} fontClass={fontClass} /> */}
      <div className="bottomVignette"></div>

    </div>
  );
};

export default Featured;
