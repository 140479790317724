import { fetchApiData, updateApiData, ideabizUpdateApi } from "../../utils/utils";

export const getIdeabizToken = async (appInfo) => {
  const api = `subscription/ideabiz/token`;
  return await fetchApiData(appInfo, api);
};

export const ideabizSubscribePhone = async (appInfo, data) => {
  const api = `subscription/ideabiz/subscribe`;
  return await ideabizUpdateApi(appInfo, api, data);
}

export const ideabizVerfyPhonePin = async (appInfo, data) => {
  const api = `subscription/ideabiz/submitPIN`;
  return await ideabizUpdateApi(appInfo, api, data);
}

export const ideabizPurchaseSubscription = async (appInfo, data) => {
  const api = `subscription/ideabiz/purchase`;
  return await ideabizUpdateApi(appInfo, api, data);
}

export const getSubscriptionList = async (appInfo) => {
  const api = `subscription/list`;
  return await fetchApiData(appInfo, api);
};

export const getUpgradeSubscriptionList = async (appInfo, subId) => {
  const api = "subscription/premium";
  const params = {
    pubid: appInfo?.projectDetails?.projectConfig?.pubid,
    subscription_id: subId,
  };
  return await fetchApiData(appInfo, api, params);
};

export const applyCouponCode = async (appInfo, coupon, subId) => {
  const api = `coupon/check?code=${coupon}&sub_id=${subId}`;
  return await fetchApiData(appInfo, api);
};

export const stripeSession = async (appInfo, values) => {
  const pubId = appInfo?.projectDetails?.projectConfig?.pubid;
  const countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
  const params = {
    pubid: pubId,
    sub_id: values?.subId,
    country_code: countryCode,
    coupon_id: values?.couponId,
  };
  const api = "subscription/stripe/session";
  return await fetchApiData(appInfo, api, params);
};

export const cinetPayApiSession = async (appInfo, values) => {
  const api = "subscription/cinetpay/initiate";
  const location = {
    values: { sub_id: values?.subId, }
  }
  return await updateApiData(appInfo, api, location);
};
export const subscriptionCheck = async (appInfo, transaction_id, token) => {
  const api = `subscription/cinetpay/check`;
  const params = {
    transaction_id,
    token,
  };
  return await fetchApiData(appInfo, api, params);
};

export const getAndroidSubscriptionDetails = async (appInfo, androidtoken) => {
  const pubId = appInfo?.projectDetails?.projectConfig?.pubid;
  const countryCode = appInfo?.projectDetails?.geoInfo?.countryCode;
  const params = {
    pubid: pubId,
    key: androidtoken,
    country_code: countryCode,
  };
  const api = "subscription/details";
  return await fetchApiData(appInfo, api, params);
};

export const getVideoSubscription = async (appInfo, id, type) => {
  const api = `subscription/active`;
  const referralCode = localStorage.getItem("referralCode");
  const params = {
    video_id: type === "video" ? id : null,
    event_id: type === "event" ? id : null,
    channel_id: type === "channel" ? id : null,
    coupon_code: referralCode,
  };
  return await fetchApiData(appInfo, api, params);
};



